<template>
	<v-container
		id="PermissionGroup Component"
		fluid
		tag="section">
		<v-row dense>
			<v-dialog
				width="80%"
				class="col-6 ml-auto mr-auto"
				@click:outside="cancelCreate"
				@keydown.esc="cancelCreate"
				v-model="createDialog">
				<v-card>
					<v-card-title :class="'headline primary pb-3 mb-3'">
						Create Permission Group
					</v-card-title>
					<v-card-text class="my-2 py-2">
						<v-form
							ref="form"
							v-model="validEdit">
							<v-col
								cols="3"
								class="mt-0 pt-0">
								<v-text-field
									autofocus
									:rules="[
										rules.required,
										rules.minMaxLength(1, 40),
										noSameName,
									]"
									v-model="textField"
									label="Name"></v-text-field>
							</v-col>

							<v-slide-y-transition>
								<span
									v-if="invalidForm"
									class="error--text mb-0 pb-0">
									At least one permission must be selected.
								</span>
							</v-slide-y-transition>
							<UserEditSections
								class="mt-2"
								ref="createSections"
								:class="{ invalidForm: invalidForm }"
								:readonly="false"
								@change="
									(newVal) => {
										this.newPerms = newVal;
									}
								"></UserEditSections>
						</v-form>
					</v-card-text>
					<v-card-actions class="stickyButtons2">
						<v-btn
							@click="cancelCreate"
							text>
							Cancel
						</v-btn>
						<v-spacer></v-spacer>
						<v-btn
							@click="unholyCrap"
							:disabled="!validEdit"
							color="primary"
							text>
							Submit
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
			<v-col>
				<v-card class="v-card--material pa-3">
					<CardHeading
						:color="'secondary'"
						:loading="loading"
						:loadingTitle="`Loading permission group...`"
						:title="selectedItem"></CardHeading>
					<v-row class="align-center">
						<v-col
							cols="9"
							md="4"
							class="pa-0 pl-2">
							<v-autocomplete
								class="ml-2 mr-2 mr-md-3"
								:disabled="loading"
								:loading="loading"
								label="Groups"
								v-model="selectedItem"
								item-text="name"
								@change="rowClickHandle"
								:items="list"></v-autocomplete>
						</v-col>
						<v-col
							cols="3"
							md="4"
							class="px-0 pt-0 pb-2 pb-md-4">
							<ConfirmDialog2
								v-if="list.length > 0"
								persistent
								:confirmBtnText="'Delete'"
								:confirmBtnColor="'error'"
								:cancelBtnColor="'kajot-text'"
								:cancelBtnText="'Back'"
								:shouldShow="true"
								@confirm="deleteItem">
								<template v-slot:body>
									This permission group will be deleted.
									<br />
									Do you wish to continue ?
								</template>
								<template v-slot:default="{ showConfirm }">
									<v-btn
										v-if="
											_usrFlagsSome({
												key: 'management.groups.detail',
												val: permissions.DELETE,
											})
										"
										fab
										:small="!isMobile"
										:x-small="isMobile"
										class="error mr-1 mr-md-3"
										@click="showConfirm">
										<v-icon>mdi-delete</v-icon>
									</v-btn>
								</template>
							</ConfirmDialog2>
							<v-btn
								v-if="
									_usrFlagsSome({
										key: 'management.groups.detail',
										val: permissions.CREATE,
									})
								"
								fab
								:small="!isMobile"
								:x-small="isMobile"
								class="primary"
								@click="createDialog = true">
								<v-icon class="pt-1">mdi-plus</v-icon>
							</v-btn>
						</v-col>
					</v-row>
					<UserEditSections
						:loading="loading"
						class="mx-2 mt-1"
						ref="groupSections"
						:usersPerms="detail.sections"
						:readonly="
							!_usrFlagsSome({
								key: 'management.groups.detail',
								val: permissions.EDIT,
							})
						"
						@change="
							(newVal) => {
								this.changedPerms = newVal;
							}
						"></UserEditSections>
					<v-card-actions
						v-if="
							_usrFlagsSome({
								key: 'management.groups.detail',
								val: permissions.EDIT,
							})
						"
						class="stickyButtons">
						<v-spacer></v-spacer>

						<v-btn
							@click="$refs.groupSections.reset()"
							:disabled="disableSubmit"
							text>
							<v-icon
								fab
								small
								class="primary--text mr-1">
								mdi-restore
							</v-icon>
							Reset
						</v-btn>
						<v-btn
							@click="submitGroup"
							:disabled="disableSubmit"
							text>
							Submit
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import permissions from "../../mixins/permissions";
import table from "../../mixins/table";
import _ from "lodash";
import CardHeading from "../../components/shared/CardHeading.vue";
import UserEditSections from "../../components/users/UserEditSections.vue";
import { rules as defaultRules } from "../../plugins/rules.ts";
import ConfirmDialog2 from "../../components/shared/ConfirmDialog2.vue";
import { isMobile } from "../../constants/helpers";

export default {
	components: {
		CardHeading,
		UserEditSections,
		ConfirmDialog2,
	},
	mixins: [permissions, table],
	data() {
		return {
			rules: { ...defaultRules },
			noSameName: (value) => {
				return (
					(!this.list
						.map((el) => el.name.toLowerCase())
						.includes(value.toLowerCase()) &&
						!this.casinos
							.map((el) => el.name.toLowerCase())
							.includes(value.toLowerCase())) ||
					"Group already exists."
				);
			},
			changedPerms: {},
			newPerms: {},
			textField: "",
			invalidForm: false,
			validEdit: false,
			createDialog: false,
			selectedItem: "",
			controller: new AbortController(),
			modifyGroup: false,
			loading: true,
			permGroupHeaders: [{ text: "Name", value: "name", align: "center" }],
		};
	},
	computed: {
		isMobile,
		groupNames() {
			return this.list.map((el) => el.name.toLowerCase());
		},
		confirmDelete() {
			return this.list.length < 3 || this.selectedItem === "admin";
		},
		validCreate() {
			if (!this.validEdit) return false;
			return JSON.stringify(this.newPerms) != "{}";
		},
		...mapGetters("permissions", {
			list: "permGroups",
			casinos: "list",
			detail: "detail",
		}),
		disableSubmit() {
			return (
				this.loading ||
				this.selectedItem == "" ||
				_.isEqual(this?.changedPerms, this.detail?.sections)
			);
		},
	},
	methods: {
		async cancelCreate() {
			this.createDialog = false;
			this.textField = "";
			this.$refs.createSections.zeroOut();
			await this.$nextTick();
			this.invalidForm = false;
		},
		...mapActions("permissions", {
			loadList: "loadGroupList",
			loadCasinos: "loadList",
			loadDetail: "loadDetail",
			create: "create",
			delete: "delete",
			edit: "edit",
		}),
		async unholyCrap() {
			if (!this.validCreate) {
				this.invalidForm = true;
				return;
			}
			this.createDialog = false;
			this.loading = true;
			await this.create({
				data: {
					sections: this.newPerms,
					name: this.textField?.toLowerCase(),
					app: "logan",
				},
			});
			await this.loadList();
			await this.loadCasinos();
			this.selectedItem = this.textField?.toLowerCase();
			await this.loadDetail(this.selectedItem);
			this.loading = false;
			this.textField = "";
		},
		async deleteItem() {
			await this.delete(this.selectedItem);
			this.loading = true;
			await this.loadList();
			await this.loadCasinos();
			if (this.list.length <= 0) return;
			const preselect = this.$route.query.selected;
			let preselectIndex = this.list.findIndex((el) => el.name === preselect);
			if (preselectIndex === -1) preselectIndex = 0;
			this.selectedItem = this.list[preselectIndex].name;
			await this.loadDetail(this.selectedItem);
			this.loading = false;
		},
		async rowClickHandle(e) {
			if (!e) {
				return;
			}
			this.loading = true;
			this.selectedItem = e.toLowerCase();
			await this.loadList();
			await this.loadCasinos();
			await this.loadDetail(e);
			this.loading = false;
		},
		async submitGroup() {
			await this.edit({
				data: {
					sections: this.changedPerms,
					casinos: this.detail.casinos,
					name: this.selectedItem.toLowerCase(),
					app: "logan",
				},
			});
			this.loading = true;
			await this.loadList();
			await this.loadCasinos();
			await this.loadDetail(this.selectedItem);
			this.loading = false;
		},
	},
	watch: {
		newPerms(val) {
			if (val && JSON.stringify(val) == "{}") {
				this.invalidForm = true;
			} else {
				this.invalidForm = false;
			}
		},
	},
	async created() {
		this.loading = true;
		await this.loadList();
		await this.loadCasinos();
		if (this.list.length <= 0) return;
		const preselect = this.$route.query.selected;
		let preselectIndex = this.list.findIndex((el) => el.name === preselect);
		if (preselectIndex === -1) preselectIndex = 0;
		this.selectedItem = this.list[preselectIndex].name;
		await this.loadDetail(this.selectedItem);
		this.loading = false;
	},
};
</script>

<style scoped>
.stickyButtons {
	background-color: var(--v-defaultbg-base);
	position: sticky;
	z-index: 1;
	bottom: 16px;
}

.invalidForm {
	outline: 1px solid var(--v-error-base);
}
.stickyButtons2 {
	background-color: var(--v-defaultbg-base);
	position: sticky;
	z-index: 1;
	bottom: 0px;
}
::v-deep tr.v-data-table__selected {
	background-color: var(--v-primary-base) !important;
}
</style>
